var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "kt_scrolltop",
    staticClass: "scrolltop",
    attrs: {
      "id": "kt_scrolltop"
    }
  }, [_vm._m(0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "svg-icon"
  }, [_c('i', {
    staticClass: "fas fa-arrow-up"
  })]);

}]

export { render, staticRenderFns }
<template>
  <b-overlay :show="$store.state.quickActions.loading" spinner-variant="primary" rounded="sm">
    <b-button v-if="$store.state.quickActions.loading" variant="outline-primary" class="p-5"></b-button>
    <component :is="currentComponent" v-else v-bind.sync="$store.state.quickActions.props"
      v-on="$store.state.quickActions.events">
    </component>
  </b-overlay>
</template>

<script>
import BackToWizardBtn from "@/view/components/buttons/BackToWizardBtn.vue";
import SecondmentPlansUpdate from "@/view/pages/secondment/components/SecondmentPlansUpdate.vue";

export default {
  name: "QuickActionsBtn",
  data() {
    return {
      isLoading: true,
      components: {
        BackToWizardBtn,
        SecondmentPlansUpdate
      },
    };
  },
  computed: {
    currentComponent() {
      return this.components[this.$store.state.quickActions.activeComponent];
    },
  },
};
</script>

<style></style>

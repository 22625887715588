<template>
  <form>
    <!--begin::Header-->
    <div class="d-flex align-items-center py-10 px-8 bgi-size-cover bgi-no-repeat rounded-top"
      style="background-image: url(/media/misc/bg-1.jpg)">
      <span class="btn btn-md btn-icon bg-white-o-15 mr-4">
        <i class="flaticon2-shopping-cart-1 text-success" />
      </span>
      <h4 class="text-white m-0 flex-grow-1 mr-3">{{ $t("My Cart") }}</h4>
      <span class="label label-inline label-xl label-rounded label-success">{{ totalItems }} {{ $t("Items") }}</span>
    </div>
    <!--end::Header-->

    <div v-if="totalItems > 0" class="scroll scroll-push">
      <perfect-scrollbar class="scroll" style="max-height: 30vh; position: relative;">
        <!--begin::Item-->
        <div v-for="(item, i) in items" :key="i">
          <div class="d-flex align-items-center justify-content-between p-8">
            <div class="d-flex align-items-center">
              <div class="country-width mr-2">
                <country-flag :country-iso="item.countries[0]"></country-flag>
              </div>
              <div class="d-flex flex-column mr-4">
                <div class="font-weight-bold text-dark-75 font-size-lg">
                  {{ item.name }}
                </div>
                <div>
                  <span class="text-muted mr-1">{{ $t("In") }}</span>
                  <span class="font-weight-bold mr-1 text-dark-75 font-size-3 text-capitalize">
                    {{ countriesMap[item.countries[0]]?.name }}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <span class="btn btn-xs btn-light-success font-weight-bolder mr-2" @click="removeItem(item.id)">
                {{ $t('Remove') }}
              </span>
            </div>
          </div>
          <div class="separator separator-solid"></div>
        </div>
        <!--end::Item-->
      </perfect-scrollbar>
    </div>

    <!--begin::Summary-->
    <div class="p-8">
      <div class="text-right">
        <router-link :to="{ name: 'checkout' }" class="btn btn-primary text-weight-bold" @click.native="closeMiniCart">
          {{ $t('Checkout') }}
        </router-link>
      </div>
    </div>
    <!--end::Summary-->
  </form>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import CountryFlag from "@/view/components/CountryFlag.vue";
import eventBus from "@/core/services/event/eventBus"

export default {
  name: "KTDropdownMyCart",
  components: {
    CountryFlag
  },
  computed: {
    ...mapState("cart", ["items"]),
    ...mapGetters("constants", ["countriesMap"]),
    totalItems() {
      return this.items.length
    }
  },
  methods: {
    ...mapActions("cart", ["removeItem"]),
    closeMiniCart() {
      eventBus.$emit('close-mini-cart')
    }
  }
};
</script>

<style scoped>
.country-width {
  width: 40px;
}
</style>

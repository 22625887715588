var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "kt_aside",
    staticClass: "aside aside-left aside-fixed d-flex flex-column flex-row-auto",
    attrs: {
      "id": "kt_aside"
    },
    on: {
      "mouseover": _vm.mouseEnter,
      "mouseleave": _vm.mouseLeave
    }
  }, [_c('div', {
    staticClass: "aside-menu-wrapper flex-column-fluid",
    attrs: {
      "id": "kt_aside_menu_wrapper"
    }
  }, [_c('div', {
    ref: "kt_aside_menu",
    staticClass: "aside-menu min-h-lg-800px",
    class: _vm.asideMenuClass,
    attrs: {
      "id": "kt_aside_menu",
      "data-menu-vertical": "1",
      "data-menu-dropdown-timeout": "500"
    }
  }, [_c('perfect-scrollbar', {
    staticClass: "aside-menu scroll",
    staticStyle: {
      "max-height": "90vh",
      "position": "relative"
    }
  }, [_c('KTMenu')], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "topbar"
  }, [!_vm.isOnlyMaps ? _c('b-dropdown', {
    ref: "dropdownAction",
    attrs: {
      "size": "sm",
      "variant": "link",
      "toggle-class": "topbar-item text-decoration-none",
      "no-caret": "",
      "right": "",
      "no-flip": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('div', {
          staticClass: "btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1"
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-xl"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Code/Warning-2.svg"
          }
        })], 1)])];
      },
      proxy: true
    }], null, false, 444530531)
  }, [_c('b-dropdown-text', {
    staticClass: "min-w-md-350px",
    attrs: {
      "tag": "div"
    }
  }, [_c('KTDropdownQuickAction')], 1)], 1) : _vm._e(), _vm.isAuthenticated && _vm.hasLoadedUserInfo ? [_c('KTQuickPanel')] : _vm._e(), !_vm.isOnlyMaps ? _c('b-dropdown', {
    ref: "dropdownShop",
    attrs: {
      "size": "sm",
      "variant": "link",
      "toggle-class": "topbar-item text-decoration-none",
      "no-caret": "",
      "right": "",
      "no-flip": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('div', {
          staticClass: "btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1 position-relative",
          class: {
            'pulse pulse-danger': _vm.notCartEmpty
          }
        }, [_vm.notCartEmpty ? _c('span', {
          staticClass: "label label-danger label-md position-absolute label-notification",
          attrs: {
            "aria-hidden": "true"
          }
        }, [_vm._v(_vm._s(_vm.cartItems))]) : _vm._e(), _c('span', {
          staticClass: "svg-icon svg-icon-xl"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Shopping/Cart3.svg"
          }
        })], 1), _vm.notCartEmpty ? [_c('span', {
          staticClass: "pulse-ring"
        })] : _vm._e()], 2)];
      },
      proxy: true
    }], null, false, 482480170)
  }, [_c('b-dropdown-text', {
    staticClass: "min-w-md-350px",
    attrs: {
      "tag": "div"
    }
  }, [_c('KTDropdownMyCart')], 1)], 1) : _vm._e(), _c('b-dropdown', {
    ref: "LanguageDropdown",
    attrs: {
      "size": "sm",
      "variant": "link",
      "toggle-class": "topbar-item text-decoration-none",
      "no-caret": "",
      "right": "",
      "no-flip": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('div', {
          staticClass: "btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1 position-relative"
        }, [_c('img', {
          staticClass: "h-20px w-20px rounded-sm",
          attrs: {
            "src": _vm.getLanguageFlag,
            "alt": ""
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-text', {
    staticClass: "min-w-md-175px",
    attrs: {
      "tag": "div"
    }
  }, [_c('KTDropdownLanguage', {
    on: {
      "hide-dropdown": _vm.hideDropdown
    }
  })], 1)], 1), _c('KTQuickUser'), _vm.getCurrentMaintenanceWindows ? _c('div', {
    staticClass: "maintenance-alert-container"
  }, [_c('div', {
    staticClass: "alert alert-custom alert-notice fade show",
    class: _vm.getCountdown(_vm.getCurrentMaintenanceWindows.start) ? 'alert-light-danger' : 'alert-light-warning',
    attrs: {
      "role": "alert"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "alert-text"
  }, [_c('p', [_vm._v(_vm._s(_vm.getCurrentMaintenanceWindows.message))]), _c('p', [_vm._v(_vm._s(_vm.$t('The system will be temporarily unavailable for scheduled maintenance')))]), _vm.getCountdown(_vm.getCurrentMaintenanceWindows.start) ? _c('p', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.getCurrentMaintenanceWindows.remaining) + " " + _vm._s(_vm.getCurrentMaintenanceWindows.remaining === 1 ? _vm.$t('minute remaining') : _vm.$t('minutes remaining')))]) : _c('span', [_c('span', {
    staticClass: "mr-3"
  }, [_vm._v(_vm._s(_vm.$t('from_datetime')))]), _c('span', {
    staticClass: "mr-3"
  }, [_vm._v(_vm._s(_vm.formatDateTime(_vm.getCurrentMaintenanceWindows.start)))]), _c('span', {
    staticClass: "mr-3"
  }, [_vm._v(_vm._s(_vm.$t('to_datetime')))]), _c('span', [_vm._v(_vm._s(_vm.formatDateTime(_vm.getCurrentMaintenanceWindows.end)))])])]), _c('div', {
    staticClass: "alert-close"
  }, [_c('button', {
    staticClass: "close",
    attrs: {
      "type": "button",
      "aria-label": "Close"
    },
    on: {
      "click": function click($event) {
        return _vm.closeAlert();
      }
    }
  }, [_vm._m(1)])])])]) : _vm._e()], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "alert-icon"
  }, [_c('i', {
    staticClass: "flaticon-warning"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c('i', {
    staticClass: "ki ki-close"
  })]);

}]

export { render, staticRenderFns }
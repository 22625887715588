var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    attrs: {
      "disabled": _vm.$store.state.quickActions.loading,
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_c('i', {
    class: _vm.$store.state.quickActions.loading ? _vm.icons.spinner : _vm.icons.save
  }), _vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isWizardPending,
      expression: "isWizardPending"
    }],
    staticClass: "btn btn-secondary font-weight-bold py-3 px-6 mr-2",
    attrs: {
      "to": {
        name: 'edit-secondment',
        params: {
          secondmentId: _vm.secondmentId
        }
      }
    }
  }, [_c('span', {
    staticClass: "navi-icon"
  }, [_c('i', {
    staticClass: "la la-hand-point-left"
  })]), _c('span', [_vm._v(_vm._s(_vm.$t("Back to wizard")))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
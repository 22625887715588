import api from "../api.service";

class CompanyManagedCompaniesService {
  get PATH() {
    return "company-managedcompanies";
  }

  async getPermissionLevel() {
    const res = await api.options(`${this.PATH}/`);
    return res.data.actions.POST.permission_level.choices;
  }

  async getAll(perPage, page, sortBy = null, search = null, sortDesc = null) {
    const offset = (page - 1) * perPage;
    const params = {
      limit: perPage,
      offset,
      search
    }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }

    const res = await api.get(`${this.PATH}/`, {
      params
    });
    return res.data;
  }

  async updateAcceptedCondition(id, acceptedCondition) {
    return await api.patch(`company-managedcompanies/${id}/`, { accepted_condition: acceptedCondition })
  }
}

export default new CompanyManagedCompaniesService();
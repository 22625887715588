var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footer py-4 d-flex flex-lg-column bg-white",
    attrs: {
      "id": "kt_footer"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-md-row align-items-center justify-content-between",
    class: {
      'container-fluid': _vm.widthFluid,
      container: !_vm.widthFluid
    }
  }, [_c('div', {
    staticClass: "order-2 order-md-1"
  }, [_c('span', {
    staticClass: "font-weight-bold mr-2"
  }, [_vm._v("2022-" + _vm._s(_vm.currentYear) + "©")]), _vm._v(" Copyright Arletti Partners | VAT ID: IT02519610360 ")]), _c('div', {
    staticClass: "order-1 order-md-2 nav nav-dark"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('a', {
    staticClass: "nav-link pl-3 pr-0",
    attrs: {
      "href": "https://arlettipartners.com/contattaci",
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t("Contact")))]), _c('router-link', {
    staticClass: "nav-link pl-3 pr-0",
    attrs: {
      "to": {
        name: 'terms-conditions'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Terms & Conditions")))]), _c('router-link', {
    staticClass: "nav-link pl-3 pr-0",
    attrs: {
      "to": {
        name: 'general-conditions'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("General Conditions")))]), _c('router-link', {
    staticClass: "nav-link pl-3 pr-0",
    attrs: {
      "to": {
        name: 'cookies-policy'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Cookie Policy")))]), _c('router-link', {
    staticClass: "nav-link pl-3 pr-0",
    attrs: {
      "to": {
        name: 'privacy-policy'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Privacy Policy")))])], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
import emitter from 'tiny-emitter/instance'

/**
 * Import with:
 * import eventBus from '@/core/services/event/eventBus.js';
 */
export default {
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args)
}
<template>
  <div id="kt_subheader" class="subheader py-2 py-lg-12 subheader-transparent" :class="subheaderClasses">
    <div class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      :class="{ 'container-fluid': widthFluid, container: !widthFluid }">
      <div class="d-flex align-items-center flex-wrap mr-2">
        <!--begin::Heading-->
        <div class="d-flex flex-column">
          <!--begin::Title-->
          <h2 class="font-weight-bold text-light my-2 mr-5 d-flex align-items-center">
            <span>
              {{ title }}
            </span>
            <span class="label label-light-success label-inline font-weight-bolder ml-4" data-toggle="tooltip"
              title="Versione beta, potrebbero esserci problemi legati allo sviluppo">Beta v. {{ version }}/{{ api_version
              }}</span>
          </h2>
          <ul class="d-flex align-items-center font-weight-bold my-2">
            <router-link :to="'/'" class="opacity-75 hover-opacity-100">
              <i class="flaticon2-shelter text-light icon-1x"></i>
            </router-link>
            <template v-for="(breadcrumb, i) in breadcrumbs">
              <span :key="i" class="label label-dot label-sm bg-white opacity-75 mx-3"></span>
              <router-link v-if="breadcrumb.route" :key="`${i}-${breadcrumb.id}`" :to="breadcrumb.route"
                class="text-white text-hover-white opacity-75 hover-opacity-100">
                {{ breadcrumb.title }}
              </router-link>
              <span v-if="!breadcrumb.route" :key="`${i}-${breadcrumb.id}`"
                class="text-white text-hover-white opacity-75 hover-opacity-100">
                {{ breadcrumb.title }}
              </span>
            </template>
          </ul>
        </div>
      </div>
      <div v-if="!isOnlyMaps" class="d-flex align-items-center">
        <!--begin::Button-->
        <router-link :to="{ name: 'reports' }" class="btn btn-transparent-white font-weight-bold py-3 px-6 mr-2">
          {{ $t('Reports') }}
        </router-link>
        <!--end::Button-->
        <!--begin::Dropdown-->
        <b-dropdown v-b-tooltip.hover="$t('Quick actions')" no-caret right no-flip text="Actions"
          toggle-class="font-weight-bold py-3 mr-2">
          <!--begin::Navigation-->
          <div class="navi navi-hover min-w-md-250px">
            <b-dropdown-item v-for="(action, i) in actions" :key="i" :to="{ name: action.name }" tag="div"
              class="navi-item">
              <div class="row">
                <div class="col-2">
                  <span class="navi-icon">
                    <i :class="action.icon"></i>
                  </span>
                </div>
                <div class="col-4">
                  <span class="navi-text">{{ action.title }}</span>
                </div>
              </div>
            </b-dropdown-item>
          </div>
          <!--end::Navigation-->
        </b-dropdown>
        <!--end::Dropdown-->
        <QuickActionsBtn />
        <div v-if="isApStaff && !isInApFocus">
          <b-button class="btn btn-secondary font-weight-bold py-3 px-6 mr-2 ml-5" @click="setOriginalCompany()">
            <span class="svg-icon svg-icon-sm svg-icon">
              <inline-svg src="/media/svg/icons/Communication/Reply.svg" />
            </span>
            {{ $t("A&P Italy") }}
          </b-button>
        </div>
        <div v-if="getUserManagedCompanies?.length > 1" :class="{ 'ml-5': isInApFocus }" style="position: relative">
          <span v-if="getUserManagedCompanies?.length > 1"
            class="label label-light-danger label-inline label-xl font-weight-bolder mr-2"
            style="position: absolute;top: -40px;right: -7px;">
            FOCUS: {{ getCurrentManagedCompany.company_name }}
          </span>
          <euro-select ref="companySelect" v-model="focus" asynchronous :fetch-function="getManagedCompanies"
            class-name="mb-0" :placeholder="$t('Select focus')" searchable style="min-width: 240px"></euro-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuickActionsBtn from "@/view/components/buttons/QuickActionsBtn.vue";
import { mapGetters, mapState, mapActions } from "vuex";
import eventBus from "@/core/services/event/eventBus";
import CompanyManagedCompaniesService from "@/core/services/company/company-managedcompanies.service";

export default {
  name: "KTSubheader",
  components: {
    QuickActionsBtn,
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      focus: null,
      actions: [
        {
          icon: "fas fa-plane",
          title: `${this.$t("Add")} ${this.$t("Assignment")}`,
          name: "create-secondment",
        },
        {
          icon: "fas fa-building",
          title: `${this.$t("Add")} ${this.$t("Legal person")}`,
          name: "create-legal-person",
        },
        {
          icon: "fas fa-user-tie",
          title: `${this.$t("Add")} ${this.$t("Physical person")}`,
          name: "create-physical-person",
        },
      ],
    };
  },
  computed: {
    ...mapState("constants", ["version", "api_version"]),
    ...mapState("user", ["user"]),
    ...mapGetters(["layoutConfig"]),
    ...mapGetters("user", ["getUserManagedCompanies", "getCurrentManagedCompany", "isApStaff", "isInApFocus", "getCurrentManagedCompanyId", "isOnlyMaps"]),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }
      }
      return classes.join(" ");
    },
  },
  watch: {
    focus(val) {
      if (val && val != this.user.selected_company) {
        this.setCompany(val);
      }
      this.focus = null;
    }
  },
  mounted() {
    this.focus = this.user.selected_company;
  },
  methods: {
    ...mapActions("user", ["setFocusCompany"]),
    ...mapActions("capabilities", ["loadCapabilities"]),
    ...mapActions("constants", ["loadConstants"]),
    setCompany(companyId) {
      eventBus.$emit('change-focus', companyId);
    },
    setOriginalCompany() {
      this.setCompany(this.user.company);
    },
    async getManagedCompanies(query, perPage, page) {
      const res = await CompanyManagedCompaniesService.getAll(perPage, page, null, query);
      return {
        count: res.count,
        results: res.results.map(el => ({
          value: el.company.id,
          text: el.company.name
        }))
      };
    },
  }
}
</script>

<style lang="scss">
.b-dropdown-text {
  padding: 0;
}

.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<template>
  <ul class="navi navi-hover py-4">
    <template v-for="(item, i) in languages">
      <li :key="i" class="navi-item" :class="{ 'navi-item-active': isActiveLanguage(item.lang) }">
        <a href="#" class="navi-link" :data-lang="item.lang" @click="selectedLanguage">
          <span class="symbol symbol-20 mr-3">
            <img :src="item.flag" alt="" />
          </span>
          <span class="navi-text">{{ item.name }}</span>
        </a>
      </li>
    </template>
  </ul>
</template>

<script>
import i18nService from "@/core/services/i18n.service.js";
import {useRouter} from "vue-router/composables";

export default {
  name: "KTDropdownLanguage",
  setup() {
    const router = useRouter()

    return {
      router,
    }
  },
  data() {
    return {
      languages: i18nService.languages
    };
  },
  computed: {
    activeLanguage() {
      return i18nService.getActiveLanguage();
    }
  },
  methods: {
    selectedLanguage(e) {
      const el = e.target.closest(".navi-link");
      const lang = el.getAttribute("data-lang");
      const restrictedPaths = ['create-secondment', 'edit-secondment'];
      // Temporary to avoid secondment wizard bug, TODO permanent fix
      if (!restrictedPaths.includes(this.router.currentRoute.name)) {
        this.$i18n.locale = lang
        i18nService.setActiveLanguage(lang);
      }
      
      this.$emit('hide-dropdown');
    },
    isActiveLanguage(current) {
      return this.activeLanguage === current;
    }
  }
};
</script>

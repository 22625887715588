<template>
  <menu-nav v-if="!isOnlyMaps">
    <menu-nav-dropdown :label="$t('Company')" is-classic>
      <menu-nav-link :label="$t('Company information')"
        :to="{ name: 'detail-legal-person', params: { companyID: user.selected_company } }"
        icon="Home/Building.svg"></menu-nav-link>
      <menu-nav-dropdown-nested v-if="!isInApFocus" :label="$t('Users')" icon="Communication/Group.svg">
        <menu-nav-link :label="$t('Manage')" :to="{ name: 'focus-users.user-manage' }"
          icon="Layout/Layout-top-panel-6.svg"></menu-nav-link>
        <menu-nav-link :label="$t('Create')" :to="{ name: 'focus-users.user-create' }"
          icon="Code/Plus.svg"></menu-nav-link>
      </menu-nav-dropdown-nested>
      <menu-nav-link :label="$t('Reports')" :to="{ name: 'reports' }" icon="Shopping/Box2.svg"></menu-nav-link>
      <menu-nav-link :label="$t('Subscriptions')" :to="{ name: 'subscriptions' }"
        icon="Layout/Layout-4-blocks.svg"></menu-nav-link>
      <menu-nav-link :label="$t('Orders')" :to="{ name: 'manage-orders-company' }"
        icon="Layout/Layout-top-panel-6.svg"></menu-nav-link>
    </menu-nav-dropdown>

    <menu-nav-dropdown :label="$t('Employees')" is-classic :action="{ name: 'employees-manage' }">
      <menu-nav-link :label="$t('Manage')" :to="{ name: 'employees-manage' }"
        icon="Layout/Layout-top-panel-6.svg"></menu-nav-link>
      <menu-nav-link :label="$t('Create')" :to="{ name: 'create-employee' }" icon="Code/Plus.svg"></menu-nav-link>
    </menu-nav-dropdown>

    <menu-nav-dropdown :label="$t('Assignments')" is-classic menu-item-active="/secondment"
      :action="{ name: 'manage-secondment' }">
      <menu-nav-link :label="$t('Manage')" :to="{ name: 'manage-secondment' }"
        icon="Layout/Layout-top-panel-6.svg"></menu-nav-link>
      <menu-nav-link :label="$t('Create')" :to="{ name: 'create-secondment' }" icon="Code/Plus.svg"></menu-nav-link>
    </menu-nav-dropdown>

    <menu-nav-dropdown :label="$t('Entities')" is-classic>
      <menu-nav-dropdown-nested :label="$t('Physical person')" menu-item-active="/physical-person"
        :action="{ name: 'manage-physical-person' }" icon="Communication/Group.svg">
        <menu-nav-link :label="$t('Manage')" :to="{ name: 'manage-physical-person' }"
          icon="Layout/Layout-top-panel-6.svg"></menu-nav-link>
        <menu-nav-link :label="$t('Create')" :to="{ name: 'create-physical-person' }"
          icon="Code/Plus.svg"></menu-nav-link>
      </menu-nav-dropdown-nested>

      <menu-nav-dropdown-nested :label="$t('Legal person')" menu-item-active="/legal-person"
        :action="{ name: 'manage-legal-person' }" icon="Home/Building.svg">
        <menu-nav-link :label="$t('Manage')" :to="{ name: 'manage-legal-person' }"
          icon="Layout/Layout-top-panel-6.svg"></menu-nav-link>
        <menu-nav-link :label="$t('Create')" :to="{ name: 'create-legal-person' }" icon="Code/Plus.svg"></menu-nav-link>
      </menu-nav-dropdown-nested>
    </menu-nav-dropdown>

    <menu-nav-dropdown :label="$t('Documents')" is-classic>
      <menu-nav-dropdown-nested :label="$t('Employment Contract')" icon="Files/File.svg">
        <menu-nav-link :label="$t('Manage')" :to="{ name: 'employees-manage' }"
          icon="Layout/Layout-top-panel-6.svg"></menu-nav-link>
        <menu-nav-link :label="$t('Create')" :to="{ name: 'associate-contract' }" icon="Code/Plus.svg"></menu-nav-link>
      </menu-nav-dropdown-nested>
      <menu-nav-link :label="$t('Employees Documents')" icon="Files/File.svg"
        :to="{ name: 'employees-documents' }"></menu-nav-link>
    </menu-nav-dropdown>

    <menu-nav-dropdown v-if="isApStaff && isInApFocus" :label="$t('Administration')" is-classic>
      <!-- <menu-nav-link :label="$t('Nations')" :to="{ name: 'manage-nations' }" icon="Map/Compass.svg">
      </menu-nav-link> -->
      <menu-nav-dropdown-nested :label="$t('Nations')" icon="Map/Compass.svg">
        <menu-nav-link :label="$t('Manage')" :to="{ name: 'manage-nations' }"
          icon="Layout/Layout-top-panel-6.svg"></menu-nav-link>
        <menu-nav-link :label="$t('Nation groups')" :to="{ name: 'manage-groups' }"
          icon="Layout/Layout-top-panel-6.svg"></menu-nav-link>
      </menu-nav-dropdown-nested>
      <menu-nav-dropdown-nested :label="$t('National contracts')" icon="Shopping/Wallet3.svg">
        <menu-nav-link :label="$t('Manage')" :to="{ name: 'national-contract' }"
          icon="Layout/Layout-top-panel-6.svg"></menu-nav-link>
        <menu-nav-link :label="$t('Contracts Comparison wizard')" :to="{ name: 'contract-comparison' }"
          icon="General/Clipboard.svg"></menu-nav-link>
        <menu-nav-link :label="$t('Contracts Comparison List')" :to="{ name: 'contract-comparison-list' }"
          icon="Files/Folder-star.svg"></menu-nav-link>
      </menu-nav-dropdown-nested>
      <menu-nav-dropdown-nested :label="$t('Users')" icon="Communication/Group.svg">
        <menu-nav-link :label="$t('Atlasposting Users')" :to="{ name: 'administration-users-manage' }"
          icon="Communication/Group.svg"></menu-nav-link>
        <menu-nav-link :label="$t('Atlasposting Staff')" :to="{ name: 'administration-staff-manage' }"
          icon="Tools/Hummer.svg"></menu-nav-link>
      </menu-nav-dropdown-nested>
      <menu-nav-dropdown-nested :label="$t('NPG')" icon="Home/Earth.svg">
        <menu-nav-link :label="$t('Manage')" :to="{ name: 'manage-npg' }"
          icon="Layout/Layout-top-panel-6.svg"></menu-nav-link>
        <menu-nav-link :label="$t('Create')" :to="{ name: 'npg-form' }" icon="Code/Plus.svg"></menu-nav-link>
      </menu-nav-dropdown-nested>
      <menu-nav-link :label="$t('Manage Focus')" :to="{ name: 'manage-focus' }" icon="Home/Building.svg"></menu-nav-link>
      <menu-nav-link :label="$t('Manage Orders')" :to="{ name: 'manage-orders' }"
        icon="Shopping/Wallet.svg"></menu-nav-link>
      <menu-nav-link :label="$t('Assignments Administration')" :to="{ name: 'admin-secondments' }"
        icon="Tools/Compass.svg"></menu-nav-link>
    </menu-nav-dropdown>
  </menu-nav>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import icons from "@/core/config/icons.js";
export default {
  name: "KTMenu",

  data() {
    return {
      icons,
    };
  },

  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["isApStaff", "isInApFocus", "isOnlyMaps"]),
  },
};
</script>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "topbar-item"
  }, [_c('div', {
    staticClass: "btn btn-icon btn-hover-transparent-white btn-lg mr-1 position-relative",
    class: {
      'pulse pulse-danger': _vm.hasNotification
    },
    attrs: {
      "id": "kt_quick_panel_toggle"
    }
  }, [_vm.hasNotification ? _c('span', {
    staticClass: "label label-danger label-md position-absolute label-notification px-1",
    staticStyle: {
      "font-size": "0.65rem"
    },
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v(_vm._s(_vm.getTotalUnread))]) : _vm._e(), _c('span', {
    staticClass: "svg-icon svg-icon-xl"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/General/Notifications1.svg"
    }
  })], 1), _vm.hasNotification ? [_c('span', {
    staticClass: "pulse-ring"
  })] : _vm._e()], 2), _c('div', {
    ref: "kt_quick_panel",
    staticClass: "offcanvas offcanvas-right pt-5 pb-5",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "id": "kt_quick_panel"
    }
  }, [_c('div', {
    staticClass: "offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5"
  }, [_c('ul', {
    staticClass: "nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10",
    attrs: {
      "role": "tablist"
    }
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('a', {
    staticClass: "nav-link active",
    attrs: {
      "data-tab": "0",
      "data-toggle": "tab",
      "href": "#",
      "role": "tab",
      "aria-selected": "true"
    },
    on: {
      "click": _vm.setActiveTab
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Notifications')) + " ")])]), _c('li', {
    staticClass: "nav-item"
  }, [_c('a', {
    staticClass: "nav-link",
    attrs: {
      "data-tab": "1",
      "data-toggle": "tab",
      "href": "#",
      "role": "tab",
      "aria-selected": "false"
    },
    on: {
      "click": _vm.setActiveTab
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Activity Feed')) + " ")])])]), _vm._m(0)]), _c('div', {
    staticClass: "offcanvas-content px-10"
  }, [_c('div', {
    staticClass: "tab-content"
  }, [_c('b-tabs', {
    staticClass: "hide-tabs",
    model: {
      value: _vm.tabIndex,
      callback: function callback($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, [_c('b-tab', {
    attrs: {
      "active": ""
    }
  }, [_c('div', {
    staticClass: "scroll pr-7 mr-n7",
    attrs: {
      "id": "kt_quick_panel_notifications"
    }
  }, [_c('div', {
    staticClass: "navi navi-icon-circle navi-spacer-x-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('router-link', {
    staticClass: "btn btn-light-primary btn-sm font-weight-bolder",
    attrs: {
      "to": {
        name: 'manage-notification'
      }
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.closeOffcanvas.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_vm._v(" " + _vm._s(_vm.$t("See all")) + " ")])]), _vm.notificationsToRead.length ? _c('div', [_c('b-button', {
    staticClass: "btn btn-sm font-weight-bolder w-100",
    on: {
      "click": _vm.setAllNotificationsAsRead
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Mark all as read")) + " ")])], 1) : _vm._e()], 1), _c('div', {
    staticClass: "separator separator-dashed mt-5 mb-1"
  }), _vm.notificationsToRead.length == 0 ? _c('div', {
    staticClass: "alert alert-custom alert-light-info mt-5"
  }, [_c('div', {
    staticClass: "alert-text"
  }, [_vm._v(_vm._s(_vm.$t("You have no notifications")))])]) : _vm._e(), _vm._l(_vm.unreadNotifications, function (item, i) {
    var _item$data, _item$data$data, _item$data2, _item$data2$data;
    return [[_c('div', {
      key: i,
      staticClass: "navi-item cursor-pointer",
      on: {
        "click": function click($event) {
          return _vm.setReadNotification(item.data.id);
        }
      }
    }, [_c('div', {
      staticClass: "navi-link rounded"
    }, [_c('div', {
      staticClass: "symbol symbol-50 mr-3"
    }, [_c('div', {
      staticClass: "symbol-label"
    }, [_c('i', {
      staticClass: "icon-lg",
      class: _vm.getIcon(item.data.action_object_content_type_model)
    })])]), _c('div', {
      staticClass: "navi-text"
    }, [_c('div', {
      staticClass: "font-weight-bold font-size-md text-capitalize",
      class: {
        'text-muted': !item.data.unread
      }
    }, [_vm._v(" " + _vm._s(item.data.verb.replace(':', '')) + " ")]), _c('div', {
      staticClass: "font-size-sm text-muted"
    }, [_vm._v(" " + _vm._s(item.data.action_object_representation) + " ")]), _c('div', {
      staticClass: "font-size-xs text-muted"
    }, [_vm._v(" " + _vm._s(_vm.DateService.formatIfRecent(item.data.timestamp, _vm.date_format, _vm.timezone)) + " ")])]), (_item$data = item.data) !== null && _item$data !== void 0 && (_item$data$data = _item$data.data) !== null && _item$data$data !== void 0 && _item$data$data.action ? _c('b-button', {
      staticClass: "btn btn-primary btn-xs mr-3",
      attrs: {
        "variant": "light-primary",
        "title": ((_item$data2 = item.data) === null || _item$data2 === void 0 ? void 0 : (_item$data2$data = _item$data2.data) === null || _item$data2$data === void 0 ? void 0 : _item$data2$data.action_label) || this.$t('Go to')
      },
      on: {
        "click": function click($event) {
          var _item$data3, _item$data3$data;
          return _vm.performAction((_item$data3 = item.data) === null || _item$data3 === void 0 ? void 0 : (_item$data3$data = _item$data3.data) === null || _item$data3$data === void 0 ? void 0 : _item$data3$data.action);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-arrow-right"
    })]) : _vm._e()], 1)])]];
  })], 2)])]), _c('b-tab', [_c('div', {
    staticClass: "scroll pr-7 mr-n7",
    attrs: {
      "id": "kt_quick_panel_logs"
    }
  }, [_vm._l(_vm.activityFeeds, function (item, i) {
    return [_c('div', {
      key: i,
      staticClass: "d-flex align-items-center rounded p-5 mb-5 cursor-default",
      class: "bg-light-".concat(_vm.getBgColor(item.data.target_content_type_model))
    }, [item.data.target_content_type_model == 'country' ? _c('span', {
      staticClass: "mr-4"
    }, [_c('CountryFlag', {
      attrs: {
        "country-iso": item.data.target_object_id
      }
    })], 1) : _c('span', {
      staticClass: "svg-icon mr-5",
      class: "svg-icon-".concat(_vm.getBgColor(item.data.target_content_type_model))
    }, [_c('inline-svg', {
      staticClass: "h-50 align-self-center",
      attrs: {
        "src": _vm.getIconFeed(item.data.target_content_type_model)
      }
    })], 1), _c('div', {
      staticClass: "d-flex flex-column flex-grow-1 mr-2"
    }, [_c('div', {
      staticClass: "font-weight-normal text-dark-75 font-size-lg mb-1 text-capitalize"
    }, [_vm._v(_vm._s(item.data.verb.replace(':', '')))]), _c('span', {
      staticClass: "text-muted font-size-sm"
    }, [item.data.target_representation != 'None' ? [_vm._v(" " + _vm._s(item.data.target_representation) + " ")] : [_vm._v(" " + _vm._s(item.data.actor_representation) + " ")], _vm._v(" " + _vm._s(_vm.DateService.format(item.data.timestamp, _vm.dateFormat)) + " ")], 2)])])];
  })], 2)])], 1)], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "offcanvas-close mt-n1 pr-5"
  }, [_c('a', {
    staticClass: "btn btn-xs btn-icon btn-light btn-hover-primary",
    attrs: {
      "id": "kt_quick_panel_close",
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ki ki-close icon-xs text-muted"
  })])]);

}]

export { render, staticRenderFns }
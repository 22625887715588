var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-mobile align-items-center",
    class: _vm.headerClasses,
    attrs: {
      "id": "kt_header_mobile"
    }
  }, [_c('a', {
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    staticClass: "logo-default max-h-30px",
    style: {
      marginLeft: '-8px'
    },
    attrs: {
      "alt": "Logo",
      "src": _vm.headerLogo
    }
  })]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.asideEnabled ? _c('button', {
    staticClass: "btn p-0 burger-icon burger-icon-left",
    attrs: {
      "id": "kt_aside_mobile_toggle"
    }
  }, [_c('span')]) : _vm._e(), _c('button', {
    ref: "kt_header_mobile_toggle",
    staticClass: "btn p-0 burger-icon ml-4",
    attrs: {
      "id": "kt_header_mobile_toggle"
    }
  }, [_c('span')]), _c('button', {
    ref: "kt_header_mobile_topbar_toggle",
    staticClass: "btn btn-icon btn-hover-transparent-white p-0 ml-3",
    attrs: {
      "id": "kt_header_mobile_topbar_toggle"
    }
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-xl"
  }, [_c('inline-svg', {
    staticClass: "svg-icon",
    attrs: {
      "src": require('@/assets/media/svg/icons/General/User.svg')
    }
  })], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
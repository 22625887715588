<template>
  <div class="topbar-item">
    <div id="kt_quick_user_toggle"
      class="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto">
      <span class="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
      <span class="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-1">{{ user.first_name
        }}</span>
      <span class="text-white opacity-80 font-weight-bolder font-size-base d-none d-md-inline mr-4">
        <span v-if="user.is_ap_staff">({{ $t("Staff") }})</span>
        <span v-else-if="isAfterSale">({{ $t("After sale") }})</span>
        <span v-else>({{ $t("Admin") }})</span>
      </span>
      <span class="symbol symbol-35">
        <span class="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30">{{ user.first_name[0]
          }}</span>
      </span>
    </div>

    <div id="kt_quick_user" ref="kt_quick_user" class="offcanvas offcanvas-right p-10">
      <!--begin::Header-->
      <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 class="font-weight-bold m-0">
          {{ $t("User Profile") }}
          <small class="text-muted font-size-sm ml-2"> {{ $t("Beta v.") }} {{ version }}/{{ api_version }}</small>
        </h3>
        <a id="kt_quick_user_close" href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary">
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar class="offcanvas-content pr-5 mr-n5 scroll" style="max-height: 90vh; position: relative">
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <avatar size="100px" :avatar-image="user.avatar" :avatar-text="`${user.first_name[0]}${user.last_name[0]}`"
            class="mr-5"></avatar>
          <div class="d-flex flex-column">
            <a href="#" class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
              {{ getUserFullName }}
            </a>
            <div class="text-muted mt-1">
              <span v-if="user.is_ap_staff">{{ $t("Staff") }}</span>
              <span v-else-if="isAfterSale">{{ $t("After sale") }}</span>
              <span v-else>{{ $t("Admin") }}</span>
            </div>
            <div class="navi mt-2">
              <button class="btn btn-sm btn-light-primary font-weight-bolder py-2 px-5" @click="onLogout">
                {{ $t("Sign Out") }}
              </button>
            </div>
          </div>
        </div>
        <!--end::Header-->
        <div v-if="!isOnlyMaps" class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div v-if="!isOnlyMaps" class="navi navi-spacer-x-0 p-0">
          <!--begin::Item-->
          <router-link :to="{ name: 'detail-physical-person', params: { userID: user.persona } }" href="#"
            class="navi-item" @click.native="closeOffcanvas">
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg src="/media/svg/icons/General/Notification2.svg" />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">
                  {{ $t("My Profile") }}
                </div>
                <div class="text-muted">{{ $t("Account settings and more") }}</div>
              </div>
            </div>
          </router-link>

          <router-link :to="{ name: 'detail-physical-orders', params: { userID: user.persona } }" href="#"
            class="navi-item" @click.native="closeOffcanvas">
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-warning">
                    <!--begin::Svg Icon-->
                    <inline-svg src="/media/svg/icons/Shopping/Wallet.svg" />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">
                  {{ $t("My orders") }}
                </div>
                <div class="text-muted"> {{ $t("Manage your orders") }}</div>
              </div>
            </div>
          </router-link>

          <router-link :to="{ name: 'detail-legal-person', params: { companyID: user.selected_company } }" href="#"
            class="navi-item" @click.native="closeOffcanvas">
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <!--begin::Svg Icon-->
                    <inline-svg src="/media/svg/icons/Home/Building.svg" />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">
                  <template v-if="user.selected_company_kind">{{ $t("My Professional Work") }}</template>

                  <template v-else>
                    {{ $t("My Company") }}
                  </template>
                </div>
                <div class="text-muted"> {{ $t("Manage settings and more") }}</div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
        </div>
        <!--end::Nav-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<script>
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import { mapActions, mapGetters, mapState } from "vuex";
import UsersService from "@/core/services/users.service";
import select2 from 'select2';
import eventBus from "@/core/services/event/eventBus";
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module.js";
window.select2 = select2;
export default {
  name: "KTQuickUser",

  computed: {
    ...mapState("constants", ["version", "api_version"]),
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["getUserID", "getCurrentManagedCompanyId", "getUserFullName", "getCurrentManagedCompany", "isOnlyMaps", "isAfterSale", "getCurrentManagedCompany", "isApStaff"]),
  },

  async mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
    eventBus.$on('change-focus', (companyId, path = null) => {
      this.setCompany(companyId, path);
    });
  },

  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("user", ["setFocusCompany", "setAcceptedCondition"]),
    ...mapActions("capabilities", ["loadCapabilities"]),
    ...mapActions("constants", ["loadConstants"]),
    ...mapActions("cart", ["initCart"]),

    onLogout() {
      this.logout();
      this.$store.commit('auth/reset');
      this.$store.commit('user/reset');
      this.$store.commit('npg/reset');
      this.$store.commit('constants/reset');
      this.$store.commit('wizard/reset');
      this.$store.commit('quickActions/reset');
      this.$store.commit('source/reset');
      this.$store.commit('addresses/reset');
      this.$store.commit('notification/reset');
      this.$store.commit('cart/reset');
      this.$store.commit('capabilities/reset');
      this.$router.push({ name: "login" });
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    async setCompany(companyId, path = null) {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      await UsersService.setFocusCompany(companyId);
      await this.setFocusCompany(companyId);
      this.$store.commit('capabilities/reset');
      this.$store.commit('constants/reset');
      await this.loadCapabilities();
      await this.loadConstants();
      this.initCart({ userId: this.getUserID, companyId: this.getCurrentManagedCompanyId });
      this.closeOffcanvas();
      if (path) {
        this.$router.push(path, () => {
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading")
        })
      } else {
        if (this.$route.name != "dashboard") {
          this.$router.push({ name: "dashboard" }, () => {
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading")
          });
        }
        else {
          this.$router.go();
          setTimeout(() => {
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading")
          }, 2000);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}

.user-mail {
  width: 150px;
  overflow-wrap: break-word;

  .navi-text {
    max-width: 100%;
  }
}
</style>
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-loader page-loader-logo custom-loader"
  }, [_c('img', {
    attrs: {
      "alt": "Logo",
      "src": _vm.logo,
      "width": "200"
    }
  }), _c('div', {
    staticClass: "spinner",
    class: _vm.spinnerClass || 'spinner-light'
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Loader', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loaderEnabled,
      expression: "loaderEnabled"
    }],
    attrs: {
      "logo": _vm.loaderLogo
    }
  }), _vm.isAuthenticated && _vm.hasLoadedUserInfo ? _c('div', {
    staticClass: "d-flex flex-column flex-root"
  }, [_c('KTHeaderMobile'), _c('div', {
    staticClass: "d-flex flex-row flex-column-fluid page"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-row-fluid wrapper",
    attrs: {
      "id": "kt_wrapper"
    }
  }, [_c('KTHeader'), _c('div', {
    staticClass: "content d-flex flex-column flex-column-fluid",
    style: {
      minHeight: '100vh'
    },
    attrs: {
      "id": "kt_content"
    }
  }, [_vm.subheaderDisplay ? _c('KTSubheader', {
    attrs: {
      "breadcrumbs": _vm.breadcrumbs,
      "title": _vm.pageTitle
    }
  }) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-column-fluid"
  }, [_c('div', {
    class: {
      'container-fluid': _vm.contentFluid,
      container: !_vm.contentFluid
    }
  }, [_vm.asideEnabled ? _c('KTAside') : _vm._e(), _c('transition', {
    attrs: {
      "name": "fade-in-up"
    }
  }, [_vm.loaded ? _c('router-view') : _vm._e()], 1)], 1)])], 1), _c('KTFooter')], 1)]), _vm.toolbarDisplay ? _c('KTStickyToolbar') : _vm._e(), _c('KTScrollTop')], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <Loader v-show="loaderEnabled" :logo="loaderLogo"></Loader>
    <div v-if="isAuthenticated && hasLoadedUserInfo" class="d-flex flex-column flex-root">
      <!-- begin:: Header Mobile -->
      <KTHeaderMobile></KTHeaderMobile>
      <!-- end:: Header Mobile -->
      <!-- begin::Body -->
      <div class="d-flex flex-row flex-column-fluid page">
        <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
          <!-- begin:: Header -->
          <KTHeader></KTHeader>
          <!-- end:: Header -->

          <!-- begin:: Content -->
          <div id="kt_content" class="content d-flex flex-column flex-column-fluid" :style="{ minHeight: '100vh' }">
            <!-- begin:: Content Head -->

            <!-- begin:: Content Head -->
            <KTSubheader v-if="subheaderDisplay" :breadcrumbs="breadcrumbs" :title="pageTitle" />
            <!-- end:: Content Head -->

            <!-- begin:: Content Body -->
            <div class="d-flex flex-column-fluid">
              <div :class="{
                'container-fluid': contentFluid,
                container: !contentFluid,
              }">
                <!-- begin:: Aside Left -->
                <KTAside v-if="asideEnabled"></KTAside>
                <!-- end:: Aside Left -->

                <transition name="fade-in-up">
                  <router-view v-if="loaded" />
                </transition>
              </div>
            </div>
          </div>

          <KTFooter></KTFooter>
        </div>
      </div>
      <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar>
      <KTScrollTop></KTScrollTop>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
import KTStickyToolbar from "@/view/layout/extras/StickyToolbar.vue";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module.js";

export default {
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    KTStickyToolbar,
    KTScrollTop,
    Loader,
  },

  data() {
    return {
      loaded: false
    }
  },

  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapGetters(["breadcrumbs", "pageTitle", "layoutConfig"]),
    ...mapGetters("user", ["hasLoadedUserInfo", "getUserID"]),
    ...mapState("user", ["user"]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return false;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
  },

  watch: {
    isAuthenticated: function (val) {
      if (!val) {
        this.logout();
        this.$store.commit('auth/reset');
        this.$store.commit('user/reset');
        this.$store.commit('npg/reset');
        this.$store.commit('constants/reset');
        this.$store.commit('wizard/reset');
        this.$store.commit('quickActions/reset');
        this.$store.commit('source/reset');
        this.$store.commit('addresses/reset');
        this.$store.commit('notification/reset');
        this.$store.commit('cart/reset');
        this.$store.commit('capabilities/reset');
        this.$router.push({ name: "login" });
      }
    },
  },

  async mounted() {
    await this.initialize();
  },

  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("user", ["loadUserInfo"]),
    ...mapActions("capabilities", ["loadCapabilities"]),
    ...mapActions("constants", ["loadConstants"]),
    ...mapActions("cart", ["initCart"]),
    ...mapActions("categories", ["getCategories"]),

    async initialize() {
      // show page loading
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      // initialize html element classes
      HtmlClass.init(this.layoutConfig());

      // check if current user is authenticated
      if (!this.isAuthenticated) {
        this.$router.push({ name: "login" });
        return;
      }

      if (this.user == null) {
        await this.loadUserInfo().then(() => {
          return this.loadCapabilities();
        }).then(() => {
          return this.loadConstants();
        }).then(() => {
          return this.getCategories();
        }).then(() => {
          return this.initCart({ userId: this.getUserID, companyId: this.user.selected_company });
        }).then(() => {
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          this.loaded = true;
        });
      } else {
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        this.loaded = true;
      }
    },
  },
};
</script>

import { render, staticRenderFns } from "./SecondmentPlansUpdate.vue?vue&type=template&id=0e0f08da&scoped=true"
import script from "./SecondmentPlansUpdate.vue?vue&type=script&lang=js"
export * from "./SecondmentPlansUpdate.vue?vue&type=script&lang=js"
import style0 from "./SecondmentPlansUpdate.vue?vue&type=style&index=0&id=0e0f08da&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e0f08da",
  null
  
)

export default component.exports
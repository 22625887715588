<template>
  <div>
    <!--begin::Header -->
    <div class="d-flex flex-column flex-center py-10 bgi-size-cover bgi-no-repeat rounded-top"
      style="background-image: url(/media/misc/bg-1.jpg)">
      <h4 class="text-white font-weight-bold">
        My Risk Section
      </h4>
    </div>
    <!--end::Header -->

    <!--begin::Nav -->
    <div class="row row-paddingless">
      <!--begin:Item-->
      <template v-for="(item, i) in list">
        <div :key="i" class="col-6">
          <router-link :to="item.to" class="d-block py-10 px-5 text-center bg-hover-light"
            :class="{ 'border-right': i == 0 }">
            <span class="svg-icon svg-icon-3x svg-icon-success">
              <!--begin::Svg Icon-->
              <inline-svg :src="item.svg" />
              <!--end::Svg Icon-->
            </span>
            <span class="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1">
              {{ item.title }}
            </span>
            <span class="d-block text-dark-50 font-size-lg">
              {{ item.desc }}
            </span>
          </router-link>
        </div>
      </template>
      <!--end:Item-->
    </div>
    <!--end::Nav -->
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "KTDropdownQuickAction",
  data() {
    return {
      list: []
    };
  },
  computed: {
    ...mapState("user", ["user"])
  },
  mounted() {
    this.list = [
      {
        title: "Maps",
        desc: "Risk",
        svg: process.env.BASE_URL + "media/svg/icons/Home/Earth.svg",
        to: { name: 'risk-map' }
      },
      {
        title: "Reports",
        desc: "Risk",
        svg: process.env.BASE_URL + "media/svg/icons/Files/Selected-file.svg",
        to: { name: 'reports', params: { userID: this.user.persona } }
      }
    ]
  }
};
</script>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "kt_header",
    staticClass: "header",
    class: _vm.headerClasses,
    attrs: {
      "id": "kt_header"
    }
  }, [_c('div', {
    staticClass: "container d-flex align-items-stretch justify-content-between",
    class: {
      'container-fluid': _vm.widthFluid,
      container: !_vm.widthFluid
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-stretch mr-3"
  }, [_c('div', {
    staticClass: "header-logo",
    style: {
      marginLeft: '-8px'
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    staticClass: "logo-default max-w-230",
    attrs: {
      "alt": "Logo",
      "src": _vm.layoutConfig('self.logo.default')
    }
  }), _c('img', {
    staticClass: "logo-sticky max-w-40",
    attrs: {
      "alt": "Logo",
      "src": _vm.layoutConfig('self.logo.sticky')
    }
  })])], 1), _c('div', {
    ref: "kt_header_menu_wrapper",
    staticClass: "header-menu-wrapper header-menu-wrapper-left"
  }, [_vm.headerMenuEnabled ? _c('div', {
    ref: "kt_header_menu",
    staticClass: "header-menu header-menu-mobile",
    class: _vm.headerMenuClasses,
    attrs: {
      "id": "kt_header_menu"
    }
  }, [_c('KTMenu')], 1) : _vm._e()])]), _c('KTTopbar')], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <!-- begin::Page loader -->
  <div class="page-loader page-loader-logo custom-loader">
    <img alt="Logo" :src="logo" width="200" />
    <div class="spinner" :class="spinnerClass || 'spinner-light'"></div>
  </div>
  <!-- end::Page Loader -->
</template>

<script>
export default {
  name: "Loader",
  props: {
    logo: {
      type: String,
      default: ''
    },
    spinnerClass: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped>
.custom-loader {
  background-color: #0151ce;
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isOnlyMaps ? _c('menu-nav', [_c('menu-nav-dropdown', {
    attrs: {
      "label": _vm.$t('Company'),
      "is-classic": ""
    }
  }, [_c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Company information'),
      "to": {
        name: 'detail-legal-person',
        params: {
          companyID: _vm.user.selected_company
        }
      },
      "icon": "Home/Building.svg"
    }
  }), !_vm.isInApFocus ? _c('menu-nav-dropdown-nested', {
    attrs: {
      "label": _vm.$t('Users'),
      "icon": "Communication/Group.svg"
    }
  }, [_c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Manage'),
      "to": {
        name: 'focus-users.user-manage'
      },
      "icon": "Layout/Layout-top-panel-6.svg"
    }
  }), _c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Create'),
      "to": {
        name: 'focus-users.user-create'
      },
      "icon": "Code/Plus.svg"
    }
  })], 1) : _vm._e(), _c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Reports'),
      "to": {
        name: 'reports'
      },
      "icon": "Shopping/Box2.svg"
    }
  }), _c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Subscriptions'),
      "to": {
        name: 'subscriptions'
      },
      "icon": "Layout/Layout-4-blocks.svg"
    }
  }), _c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Orders'),
      "to": {
        name: 'manage-orders-company'
      },
      "icon": "Layout/Layout-top-panel-6.svg"
    }
  })], 1), _c('menu-nav-dropdown', {
    attrs: {
      "label": _vm.$t('Employees'),
      "is-classic": "",
      "action": {
        name: 'employees-manage'
      }
    }
  }, [_c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Manage'),
      "to": {
        name: 'employees-manage'
      },
      "icon": "Layout/Layout-top-panel-6.svg"
    }
  }), _c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Create'),
      "to": {
        name: 'create-employee'
      },
      "icon": "Code/Plus.svg"
    }
  })], 1), _c('menu-nav-dropdown', {
    attrs: {
      "label": _vm.$t('Assignments'),
      "is-classic": "",
      "menu-item-active": "/secondment",
      "action": {
        name: 'manage-secondment'
      }
    }
  }, [_c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Manage'),
      "to": {
        name: 'manage-secondment'
      },
      "icon": "Layout/Layout-top-panel-6.svg"
    }
  }), _c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Create'),
      "to": {
        name: 'create-secondment'
      },
      "icon": "Code/Plus.svg"
    }
  })], 1), _c('menu-nav-dropdown', {
    attrs: {
      "label": _vm.$t('Entities'),
      "is-classic": ""
    }
  }, [_c('menu-nav-dropdown-nested', {
    attrs: {
      "label": _vm.$t('Physical person'),
      "menu-item-active": "/physical-person",
      "action": {
        name: 'manage-physical-person'
      },
      "icon": "Communication/Group.svg"
    }
  }, [_c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Manage'),
      "to": {
        name: 'manage-physical-person'
      },
      "icon": "Layout/Layout-top-panel-6.svg"
    }
  }), _c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Create'),
      "to": {
        name: 'create-physical-person'
      },
      "icon": "Code/Plus.svg"
    }
  })], 1), _c('menu-nav-dropdown-nested', {
    attrs: {
      "label": _vm.$t('Legal person'),
      "menu-item-active": "/legal-person",
      "action": {
        name: 'manage-legal-person'
      },
      "icon": "Home/Building.svg"
    }
  }, [_c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Manage'),
      "to": {
        name: 'manage-legal-person'
      },
      "icon": "Layout/Layout-top-panel-6.svg"
    }
  }), _c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Create'),
      "to": {
        name: 'create-legal-person'
      },
      "icon": "Code/Plus.svg"
    }
  })], 1)], 1), _c('menu-nav-dropdown', {
    attrs: {
      "label": _vm.$t('Documents'),
      "is-classic": ""
    }
  }, [_c('menu-nav-dropdown-nested', {
    attrs: {
      "label": _vm.$t('Employment Contract'),
      "icon": "Files/File.svg"
    }
  }, [_c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Manage'),
      "to": {
        name: 'employees-manage'
      },
      "icon": "Layout/Layout-top-panel-6.svg"
    }
  }), _c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Create'),
      "to": {
        name: 'associate-contract'
      },
      "icon": "Code/Plus.svg"
    }
  })], 1), _c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Employees Documents'),
      "icon": "Files/File.svg",
      "to": {
        name: 'employees-documents'
      }
    }
  })], 1), _vm.isApStaff && _vm.isInApFocus ? _c('menu-nav-dropdown', {
    attrs: {
      "label": _vm.$t('Administration'),
      "is-classic": ""
    }
  }, [_c('menu-nav-dropdown-nested', {
    attrs: {
      "label": _vm.$t('Nations'),
      "icon": "Map/Compass.svg"
    }
  }, [_c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Manage'),
      "to": {
        name: 'manage-nations'
      },
      "icon": "Layout/Layout-top-panel-6.svg"
    }
  }), _c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Nation groups'),
      "to": {
        name: 'manage-groups'
      },
      "icon": "Layout/Layout-top-panel-6.svg"
    }
  })], 1), _c('menu-nav-dropdown-nested', {
    attrs: {
      "label": _vm.$t('National contracts'),
      "icon": "Shopping/Wallet3.svg"
    }
  }, [_c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Manage'),
      "to": {
        name: 'national-contract'
      },
      "icon": "Layout/Layout-top-panel-6.svg"
    }
  }), _c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Contracts Comparison wizard'),
      "to": {
        name: 'contract-comparison'
      },
      "icon": "General/Clipboard.svg"
    }
  }), _c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Contracts Comparison List'),
      "to": {
        name: 'contract-comparison-list'
      },
      "icon": "Files/Folder-star.svg"
    }
  })], 1), _c('menu-nav-dropdown-nested', {
    attrs: {
      "label": _vm.$t('Users'),
      "icon": "Communication/Group.svg"
    }
  }, [_c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Atlasposting Users'),
      "to": {
        name: 'administration-users-manage'
      },
      "icon": "Communication/Group.svg"
    }
  }), _c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Atlasposting Staff'),
      "to": {
        name: 'administration-staff-manage'
      },
      "icon": "Tools/Hummer.svg"
    }
  })], 1), _c('menu-nav-dropdown-nested', {
    attrs: {
      "label": _vm.$t('NPG'),
      "icon": "Home/Earth.svg"
    }
  }, [_c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Manage'),
      "to": {
        name: 'manage-npg'
      },
      "icon": "Layout/Layout-top-panel-6.svg"
    }
  }), _c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Create'),
      "to": {
        name: 'npg-form'
      },
      "icon": "Code/Plus.svg"
    }
  })], 1), _c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Manage Focus'),
      "to": {
        name: 'manage-focus'
      },
      "icon": "Home/Building.svg"
    }
  }), _c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Manage Orders'),
      "to": {
        name: 'manage-orders'
      },
      "icon": "Shopping/Wallet.svg"
    }
  }), _c('menu-nav-link', {
    attrs: {
      "label": _vm.$t('Assignments Administration'),
      "to": {
        name: 'admin-secondments'
      },
      "icon": "Tools/Compass.svg"
    }
  })], 1) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }
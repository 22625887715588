var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "navi navi-hover py-4"
  }, [_vm._l(_vm.languages, function (item, i) {
    return [_c('li', {
      key: i,
      staticClass: "navi-item",
      class: {
        'navi-item-active': _vm.isActiveLanguage(item.lang)
      }
    }, [_c('a', {
      staticClass: "navi-link",
      attrs: {
        "href": "#",
        "data-lang": item.lang
      },
      on: {
        "click": _vm.selectedLanguage
      }
    }, [_c('span', {
      staticClass: "symbol symbol-20 mr-3"
    }, [_c('img', {
      attrs: {
        "src": item.flag,
        "alt": ""
      }
    })]), _c('span', {
      staticClass: "navi-text"
    }, [_vm._v(_vm._s(item.name))])])])];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
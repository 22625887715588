var render = function render(){
  var _vm$getUserManagedCom, _vm$getUserManagedCom2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "subheader py-2 py-lg-12 subheader-transparent",
    class: _vm.subheaderClasses,
    attrs: {
      "id": "kt_subheader"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap",
    class: {
      'container-fluid': _vm.widthFluid,
      container: !_vm.widthFluid
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center flex-wrap mr-2"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('h2', {
    staticClass: "font-weight-bold text-light my-2 mr-5 d-flex align-items-center"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('span', {
    staticClass: "label label-light-success label-inline font-weight-bolder ml-4",
    attrs: {
      "data-toggle": "tooltip",
      "title": "Versione beta, potrebbero esserci problemi legati allo sviluppo"
    }
  }, [_vm._v("Beta v. " + _vm._s(_vm.version) + "/" + _vm._s(_vm.api_version))])]), _c('ul', {
    staticClass: "d-flex align-items-center font-weight-bold my-2"
  }, [_c('router-link', {
    staticClass: "opacity-75 hover-opacity-100",
    attrs: {
      "to": '/'
    }
  }, [_c('i', {
    staticClass: "flaticon2-shelter text-light icon-1x"
  })]), _vm._l(_vm.breadcrumbs, function (breadcrumb, i) {
    return [_c('span', {
      key: i,
      staticClass: "label label-dot label-sm bg-white opacity-75 mx-3"
    }), breadcrumb.route ? _c('router-link', {
      key: "".concat(i, "-").concat(breadcrumb.id),
      staticClass: "text-white text-hover-white opacity-75 hover-opacity-100",
      attrs: {
        "to": breadcrumb.route
      }
    }, [_vm._v(" " + _vm._s(breadcrumb.title) + " ")]) : _vm._e(), !breadcrumb.route ? _c('span', {
      key: "".concat(i, "-").concat(breadcrumb.id),
      staticClass: "text-white text-hover-white opacity-75 hover-opacity-100"
    }, [_vm._v(" " + _vm._s(breadcrumb.title) + " ")]) : _vm._e()];
  })], 2)])]), !_vm.isOnlyMaps ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('router-link', {
    staticClass: "btn btn-transparent-white font-weight-bold py-3 px-6 mr-2",
    attrs: {
      "to": {
        name: 'reports'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Reports')) + " ")]), _c('b-dropdown', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.$t('Quick actions'),
      expression: "$t('Quick actions')",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "no-caret": "",
      "right": "",
      "no-flip": "",
      "text": "Actions",
      "toggle-class": "font-weight-bold py-3 mr-2"
    }
  }, [_c('div', {
    staticClass: "navi navi-hover min-w-md-250px"
  }, _vm._l(_vm.actions, function (action, i) {
    return _c('b-dropdown-item', {
      key: i,
      staticClass: "navi-item",
      attrs: {
        "to": {
          name: action.name
        },
        "tag": "div"
      }
    }, [_c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-2"
    }, [_c('span', {
      staticClass: "navi-icon"
    }, [_c('i', {
      class: action.icon
    })])]), _c('div', {
      staticClass: "col-4"
    }, [_c('span', {
      staticClass: "navi-text"
    }, [_vm._v(_vm._s(action.title))])])])]);
  }), 1)]), _c('QuickActionsBtn'), _vm.isApStaff && !_vm.isInApFocus ? _c('div', [_c('b-button', {
    staticClass: "btn btn-secondary font-weight-bold py-3 px-6 mr-2 ml-5",
    on: {
      "click": function click($event) {
        return _vm.setOriginalCompany();
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-sm svg-icon"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Communication/Reply.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("A&P Italy")) + " ")])], 1) : _vm._e(), ((_vm$getUserManagedCom = _vm.getUserManagedCompanies) === null || _vm$getUserManagedCom === void 0 ? void 0 : _vm$getUserManagedCom.length) > 1 ? _c('div', {
    class: {
      'ml-5': _vm.isInApFocus
    },
    staticStyle: {
      "position": "relative"
    }
  }, [((_vm$getUserManagedCom2 = _vm.getUserManagedCompanies) === null || _vm$getUserManagedCom2 === void 0 ? void 0 : _vm$getUserManagedCom2.length) > 1 ? _c('span', {
    staticClass: "label label-light-danger label-inline label-xl font-weight-bolder mr-2",
    staticStyle: {
      "position": "absolute",
      "top": "-40px",
      "right": "-7px"
    }
  }, [_vm._v(" FOCUS: " + _vm._s(_vm.getCurrentManagedCompany.company_name) + " ")]) : _vm._e(), _c('euro-select', {
    ref: "companySelect",
    staticStyle: {
      "min-width": "240px"
    },
    attrs: {
      "asynchronous": "",
      "fetch-function": _vm.getManagedCompanies,
      "class-name": "mb-0",
      "placeholder": _vm.$t('Select focus'),
      "searchable": ""
    },
    model: {
      value: _vm.focus,
      callback: function callback($$v) {
        _vm.focus = $$v;
      },
      expression: "focus"
    }
  })], 1) : _vm._e()], 1) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('div', {
    staticClass: "row row-paddingless"
  }, [_vm._l(_vm.list, function (item, i) {
    return [_c('div', {
      key: i,
      staticClass: "col-6"
    }, [_c('router-link', {
      staticClass: "d-block py-10 px-5 text-center bg-hover-light",
      class: {
        'border-right': i == 0
      },
      attrs: {
        "to": item.to
      }
    }, [_c('span', {
      staticClass: "svg-icon svg-icon-3x svg-icon-success"
    }, [_c('inline-svg', {
      attrs: {
        "src": item.svg
      }
    })], 1), _c('span', {
      staticClass: "d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('span', {
      staticClass: "d-block text-dark-50 font-size-lg"
    }, [_vm._v(" " + _vm._s(item.desc) + " ")])])], 1)];
  })], 2)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column flex-center py-10 bgi-size-cover bgi-no-repeat rounded-top",
    staticStyle: {
      "background-image": "url(/media/misc/bg-1.jpg)"
    }
  }, [_c('h4', {
    staticClass: "text-white font-weight-bold"
  }, [_vm._v(" My Risk Section ")])]);

}]

export { render, staticRenderFns }
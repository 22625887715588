<template>
  <div class="topbar-item">
    <div id="kt_quick_panel_toggle" :class="{ 'pulse pulse-danger': hasNotification }"
      class="btn btn-icon btn-hover-transparent-white btn-lg mr-1 position-relative">
      <span v-if="hasNotification" aria-hidden="true"
        class="label label-danger label-md position-absolute label-notification px-1" style="font-size: 0.65rem">{{
          getTotalUnread }}</span>
      <span class="svg-icon svg-icon-xl">
        <inline-svg src="/media/svg/icons/General/Notifications1.svg" />
      </span>
      <template v-if="hasNotification">
        <span class="pulse-ring"></span>
      </template>
    </div>

    <!-- begin::Quick Panel -->
    <div id="kt_quick_panel" ref="kt_quick_panel" class="offcanvas offcanvas-right pt-5 pb-5" style="overflow: hidden;">
      <!--begin::Header-->
      <div class="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <ul class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"
          role="tablist">
          <li class="nav-item">
            <a class="nav-link active" data-tab="0" data-toggle="tab" href="#" role="tab" aria-selected="true"
              @click="setActiveTab">
              {{ $t('Notifications') }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-tab="1" data-toggle="tab" href="#" role="tab" aria-selected="false"
              @click="setActiveTab">
              {{ $t('Activity Feed') }}
            </a>
          </li>
        </ul>
        <div class="offcanvas-close mt-n1 pr-5">
          <a id="kt_quick_panel_close" href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary">
            <i class="ki ki-close icon-xs text-muted"></i>
          </a>
        </div>
      </div>
      <!--end::Header-->

      <div class="offcanvas-content px-10">
        <div class="tab-content">
          <b-tabs v-model="tabIndex" class="hide-tabs">
            <b-tab active>
              <div id="kt_quick_panel_notifications" class="scroll pr-7 mr-n7">
                <!--begin::Nav-->
                <div class="navi navi-icon-circle navi-spacer-x-0">
                  <div class="d-flex justify-content-between align-items-center">
                    <router-link :to="{ name: 'manage-notification' }"
                      class="btn btn-light-primary btn-sm font-weight-bolder" @click.native="closeOffcanvas">
                      <div class="d-flex">
                        {{ $t("See all") }}
                      </div>
                    </router-link>
                    <div v-if="notificationsToRead.length">
                      <b-button class="btn btn-sm font-weight-bolder w-100" @click="setAllNotificationsAsRead">
                        {{ $t("Mark all as read") }}
                      </b-button>
                    </div>
                  </div>
                  <div class="separator separator-dashed mt-5 mb-1"></div>
                  <div v-if="notificationsToRead.length == 0" class="alert alert-custom alert-light-info mt-5">
                    <div class="alert-text">{{ $t("You have no notifications") }}</div>
                  </div>
                  <template v-for="( item, i ) in unreadNotifications ">
                    <!--begin::Item-->
                    <template>
                      <div :key="i" class="navi-item cursor-pointer" @click="setReadNotification(item.data.id)">
                        <div class="navi-link rounded">
                          <div class="symbol symbol-50 mr-3">
                            <div class="symbol-label">
                              <i :class="getIcon(item.data.action_object_content_type_model)" class="icon-lg" />
                            </div>
                          </div>
                          <div class="navi-text">
                            <div class="font-weight-bold font-size-md text-capitalize"
                              :class="{ 'text-muted': !item.data.unread }">
                              {{ item.data.verb.replace(':', '') }}
                            </div>
                            <div class="font-size-sm text-muted">
                              {{ item.data.action_object_representation }}
                            </div>
                            <div class="font-size-xs text-muted">
                              {{ DateService.formatIfRecent(item.data.timestamp, date_format, timezone) }}
                            </div>
                          </div>
                          <b-button v-if="item.data?.data?.action" variant="light-primary"
                            class="btn btn-primary btn-xs mr-3" :title="item.data?.data?.action_label || this.$t('Go to')"
                            @click="performAction(item.data?.data?.action)">
                            <i class="fas fa-arrow-right"></i>
                          </b-button>
                        </div>
                      </div>
                    </template>
                    <!--end::Item-->
                  </template>
                </div>
              </div>
            </b-tab>

            <b-tab>
              <div id="kt_quick_panel_logs" class="scroll pr-7 mr-n7">
                <template v-for="( item, i ) in  activityFeeds ">
                  <!--begin::Item-->
                  <div :key="i" :class="`bg-light-${getBgColor(item.data.target_content_type_model)}`"
                    class="d-flex align-items-center rounded p-5 mb-5 cursor-default">
                    <span v-if="item.data.target_content_type_model == 'country'" class="mr-4">
                      <CountryFlag :country-iso="item.data.target_object_id"></CountryFlag>
                    </span>
                    <span v-else class="svg-icon mr-5"
                      :class="`svg-icon-${getBgColor(item.data.target_content_type_model)}`">
                      <inline-svg :src="getIconFeed(item.data.target_content_type_model)"
                        class="h-50 align-self-center" />
                    </span>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <div class="font-weight-normal text-dark-75 font-size-lg mb-1 text-capitalize">{{
                        item.data.verb.replace(':', '') }}</div>
                      <span class="text-muted font-size-sm">
                        <template v-if="item.data.target_representation != 'None'">
                          {{ item.data.target_representation }}
                        </template>
                        <template v-else>
                          {{ item.data.actor_representation }}
                        </template>
                        {{ DateService.format(item.data.timestamp, dateFormat) }}
                      </span>
                    </div>
                  </div>
                </template>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
    <!-- end::Quick Panel -->
  </div>
</template>

<script>
import TokenService from "@/core/services/token.service";
const NOTIFICATIONS_WS_URL = process.env.VUE_APP_WEBSOCKET_URL;
import KTLayoutQuickPanel from "@/assets/js/layout/extended/quick-panel.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import { mapState, mapGetters, mapActions } from "vuex";
import DateService from "@/core/services/date.service";
import CountryFlag from "@/view/components/CountryFlag.vue";
import { backendErrorSwal, confirmationSwal, notificationToast } from "@/core/helpers/swal";
import eventBus from '@/core/services/event/eventBus';

export default {
  name: "KTQuickPanel",
  components: {
    CountryFlag
  },
  data() {
    return {
      DateService,
      /**@type WebSocket */
      notificationsWs: null,
      tabIndex: 0,
      notificationsIntervalClose: null,
      connectionAttempts: 0,
    };
  },
  computed: {
    ...mapState("auth", ["tokens"]),
    ...mapGetters("auth", ["isLoggedIn"]),
    ...mapGetters("user", ["dateFormat", "date_format", "timezone"]),
    ...mapState("notification", ["notifications", "activityFeeds"]),
    ...mapGetters("notification", ["hasNotification", "getTotalUnread"]),

    notificationsToRead() {
      return this.notifications //.filter(el => el.data.unread)
    },
    unreadNotifications() {
      return this.notifications //.filter(n => n.data.unread)
    },
  },
  watch: {
    'tokens.user': {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal && newVal) {
          if (!this.notificationsWs || this.notificationsWs.readyState !== WebSocket.OPEN) {
            this.startNotificationsWebsocket();
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    // Init Quick Offcanvas Panel
    KTLayoutQuickPanel.init(this.$refs["kt_quick_panel"]);
    eventBus.$on('read-notification', this.setReadNotification)
  },

  beforeDestroy() {
    this.notificationsWs.close();
    if (this.notificationsIntervalClose) {
      clearInterval(this.notificationsIntervalClose);
      this.notificationsIntervalClose = null;
    }
  },

  methods: {
    ...mapActions("notification", ["setNotification", "setActivityFeed", "setTotalUnread", "setNotificationRead", "setAllNotificationsRead"]),

    getIcon(type) {
      if (type == 'order') { // Ordini
        return 'flaticon-shopping-basket text-success'
      }
      if (type == 'secondment') { // Cantieri
        return 'flaticon-security text-primary'
      }
      if (type == 'country') { // Aggiornamento rischio
        return 'flaticon2-heart-rate-monitor text-warning'
      }
      if (type == 'post') { //  Aggiornamento post
        return 'flaticon-feed text-warning'
      }
      return 'flaticon-bell text-success'
    },
    getIconFeed(type) {
      if (type == 'country') {
        return '/media/svg/icons/Communication/Thumbtack.svg'
      }

      return '/media/svg/icons/Communication/RSS.svg'
    },
    getBgColor(type) {
      if (type == 'order') { // Ordini
        return 'success'
      }
      if (type == 'secondment') { // Cantieri
        return 'primary'
      }
      if (type == 'country') { // Aggiornamento rischio
        return 'warning'
      }
      if (type == 'post') { //  Aggiornamento post
        return 'warning'
      }
      return 'success'
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
    startNotificationsWebsocket() {
      return new Promise(async (resolve, reject) => {
        // If this.notificationsWs already exists and is in OPEN state, do not create a new WebSocket
        if (this.notificationsWs && this.notificationsWs.readyState === WebSocket.OPEN) {
          resolve();
          return;
        }
        if (!this.isLoggedIn) {
          reject();
          return;
        }

        this.notificationsWs = new WebSocket(NOTIFICATIONS_WS_URL);

        this.notificationsWs.addEventListener("open", async () => {
          const tokenRefreshed = await TokenService.refreshTokenAndUpdateState();
          if (tokenRefreshed === null) {
            // If the token refresh failed, stop the execution
            this.notificationsWs.close();
            reject();
            return;
          }
          this.notificationsWs.send(JSON.stringify({ token: this.tokens.access }));
          this.notificationsWs.send(JSON.stringify({ action: "stats" }));
          resolve(); // Resolve the Promise when the WebSocket connection is open
        });

        this.notificationsWs.addEventListener("error", () => {
          // Handle WebSocket error
          if (this.notificationsIntervalClose) {
            clearInterval(this.notificationsIntervalClose);
            this.notificationsIntervalClose = null;
          }
          reject(); // Reject the Promise when a WebSocket error occurs
        });

        this.notificationsWs.addEventListener("close", () => {
          if (!this.notificationsIntervalClose) {
            this.handleWebSocketReconnection();
          }
        });

        this.notificationsWs.addEventListener("message", (e) => {
          const data = JSON.parse(e.data);
          if (data?.unread_notifications) {
            // Totale notifiche non lette
            this.setTotalUnread(data.unread_notifications);
          }
          if (Array.isArray(data)) {
            data.map(element => {
              if (element.message_type == 'notification') {
                this.setNotification(element);
              }
              if (element.message_type == 'feed') {
                this.setActivityFeed(element);
              }
            })
          } else {
            if (data?.message_type == 'notification') {
              this.setNotification(data);
              notificationToast()
            }
            if (data?.message_type == 'feed') {
              this.setActivityFeed(data);
            }
            if (data?.message_type == 'maintenance_windows') {
              this.setMaintenanceWindows(data);
            }
          }
        });
      });
    },

    handleWebSocketReconnection() {
      if (!this.notificationsIntervalClose) {
        this.notificationsIntervalClose = setInterval(() => {
          if (this.notificationsWs.readyState === WebSocket.CLOSED || this.notificationsWs.readyState === WebSocket.CLOSING) {
            if (!this.isLoggedIn) {
              this.stopReconnectionAttempts();
            } else {
              this.connectionAttempts++;
              if (this.connectionAttempts > 10) {
                this.stopReconnectionAttempts();
              } else {
                this.startNotificationsWebsocket().catch(() => {
                  if (this.connectionAttempts > 10) {
                    this.stopReconnectionAttempts();
                  }
                });
              }
            }
          } else {
            this.stopReconnectionAttempts()
          }
        }, 10000);
      }
    },

    stopReconnectionAttempts() {
      if (this.notificationsIntervalClose) {
        clearInterval(this.notificationsIntervalClose);
        this.notificationsIntervalClose = null;
      }
      if (this.connectionAttempts > 10) {
        backendErrorSwal(
          null,
          this.$t("Notification websocket connection failed"),
          this.$t("Please try to reload the page later..."),
        );
      }
      this.connectionAttempts = 0; // Reset attempts to prevent spamming after manual page refresh
    },
    setReadNotification(id) {
      // Segna come letta una notifica
      this.notificationsWs.send(JSON.stringify({ action: "mark_read", notification_id: id }))
      this.setNotificationRead(id)
      this.notificationsWs.send(JSON.stringify({ action: "get_paginated_notifications", limit: 1, offset: 99 }))
    },
    async setAllNotificationsAsRead() {// Segna tutte le notifiche come lette
      let { isConfirmed } = await confirmationSwal({ title: this.$t("Do you want to set all the notifications as read?") })
      if (isConfirmed) {
        this.notificationsWs.send(JSON.stringify({ action: "mark_all_as_read" }))
        this.setAllNotificationsRead()
      }
    },
    setPagination(offset) {
      // Paginazione delle notifiche
      this.notificationsWs.send(JSON.stringify({ action: "get_paginated_notifications", limit: 100, offset: offset }))
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickPanel.getElement()).hide();
    },
    performAction(action) {
      // Esegui azione
      console.log(action)
      // TODO handle actions
    },
    setMaintenanceWindows(data) {
      // set vuex state with SET_MAINTENANCE_WINDOWS mutation
      if (data.maintenance_windows) {
        this.$store.commit('constants/SET_MAINTENANCE_WINDOWS', data.maintenance_windows);
      }
    }
  }
};
</script>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs>div:not(.tab-content) {
  display: none;
}

.label-notification {
  right: -2px;
  top: -2px;
}

.cursor-default {
  cursor: default;
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "topbar-item"
  }, [_c('div', {
    staticClass: "btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto",
    attrs: {
      "id": "kt_quick_user_toggle"
    }
  }, [_c('span', {
    staticClass: "text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1"
  }, [_vm._v("Hi,")]), _c('span', {
    staticClass: "text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-1"
  }, [_vm._v(_vm._s(_vm.user.first_name))]), _c('span', {
    staticClass: "text-white opacity-80 font-weight-bolder font-size-base d-none d-md-inline mr-4"
  }, [_vm.user.is_ap_staff ? _c('span', [_vm._v("(" + _vm._s(_vm.$t("Staff")) + ")")]) : _vm.isAfterSale ? _c('span', [_vm._v("(" + _vm._s(_vm.$t("After sale")) + ")")]) : _c('span', [_vm._v("(" + _vm._s(_vm.$t("Admin")) + ")")])]), _c('span', {
    staticClass: "symbol symbol-35"
  }, [_c('span', {
    staticClass: "symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30"
  }, [_vm._v(_vm._s(_vm.user.first_name[0]))])])]), _c('div', {
    ref: "kt_quick_user",
    staticClass: "offcanvas offcanvas-right p-10",
    attrs: {
      "id": "kt_quick_user"
    }
  }, [_c('div', {
    staticClass: "offcanvas-header d-flex align-items-center justify-content-between pb-5"
  }, [_c('h3', {
    staticClass: "font-weight-bold m-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("User Profile")) + " "), _c('small', {
    staticClass: "text-muted font-size-sm ml-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("Beta v.")) + " " + _vm._s(_vm.version) + "/" + _vm._s(_vm.api_version))])]), _vm._m(0)]), _c('perfect-scrollbar', {
    staticClass: "offcanvas-content pr-5 mr-n5 scroll",
    staticStyle: {
      "max-height": "90vh",
      "position": "relative"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mt-5"
  }, [_c('avatar', {
    staticClass: "mr-5",
    attrs: {
      "size": "100px",
      "avatar-image": _vm.user.avatar,
      "avatar-text": "".concat(_vm.user.first_name[0]).concat(_vm.user.last_name[0])
    }
  }), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('a', {
    staticClass: "font-weight-bold font-size-h5 text-dark-75 text-hover-primary",
    attrs: {
      "href": "#"
    }
  }, [_vm._v(" " + _vm._s(_vm.getUserFullName) + " ")]), _c('div', {
    staticClass: "text-muted mt-1"
  }, [_vm.user.is_ap_staff ? _c('span', [_vm._v(_vm._s(_vm.$t("Staff")))]) : _vm.isAfterSale ? _c('span', [_vm._v(_vm._s(_vm.$t("After sale")))]) : _c('span', [_vm._v(_vm._s(_vm.$t("Admin")))])]), _c('div', {
    staticClass: "navi mt-2"
  }, [_c('button', {
    staticClass: "btn btn-sm btn-light-primary font-weight-bolder py-2 px-5",
    on: {
      "click": _vm.onLogout
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Sign Out")) + " ")])])])], 1), !_vm.isOnlyMaps ? _c('div', {
    staticClass: "separator separator-dashed mt-8 mb-5"
  }) : _vm._e(), !_vm.isOnlyMaps ? _c('div', {
    staticClass: "navi navi-spacer-x-0 p-0"
  }, [_c('router-link', {
    staticClass: "navi-item",
    attrs: {
      "to": {
        name: 'detail-physical-person',
        params: {
          userID: _vm.user.persona
        }
      },
      "href": "#"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.closeOffcanvas.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "navi-link"
  }, [_c('div', {
    staticClass: "symbol symbol-40 bg-light mr-3"
  }, [_c('div', {
    staticClass: "symbol-label"
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md svg-icon-success"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/General/Notification2.svg"
    }
  })], 1)])]), _c('div', {
    staticClass: "navi-text"
  }, [_c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("My Profile")) + " ")]), _c('div', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.$t("Account settings and more")))])])])]), _c('router-link', {
    staticClass: "navi-item",
    attrs: {
      "to": {
        name: 'detail-physical-orders',
        params: {
          userID: _vm.user.persona
        }
      },
      "href": "#"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.closeOffcanvas.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "navi-link"
  }, [_c('div', {
    staticClass: "symbol symbol-40 bg-light mr-3"
  }, [_c('div', {
    staticClass: "symbol-label"
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md svg-icon-warning"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Shopping/Wallet.svg"
    }
  })], 1)])]), _c('div', {
    staticClass: "navi-text"
  }, [_c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("My orders")) + " ")]), _c('div', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.$t("Manage your orders")))])])])]), _c('router-link', {
    staticClass: "navi-item",
    attrs: {
      "to": {
        name: 'detail-legal-person',
        params: {
          companyID: _vm.user.selected_company
        }
      },
      "href": "#"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.closeOffcanvas.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "navi-link"
  }, [_c('div', {
    staticClass: "symbol symbol-40 bg-light mr-3"
  }, [_c('div', {
    staticClass: "symbol-label"
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md svg-icon-primary"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Home/Building.svg"
    }
  })], 1)])]), _c('div', {
    staticClass: "navi-text"
  }, [_c('div', {
    staticClass: "font-weight-bold"
  }, [_vm.user.selected_company_kind ? [_vm._v(_vm._s(_vm.$t("My Professional Work")))] : [_vm._v(" " + _vm._s(_vm.$t("My Company")) + " ")]], 2), _c('div', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.$t("Manage settings and more")))])])])])], 1) : _vm._e()])], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "btn btn-xs btn-icon btn-light btn-hover-primary",
    attrs: {
      "id": "kt_quick_user_close",
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ki ki-close icon-xs text-muted"
  })]);

}]

export { render, staticRenderFns }
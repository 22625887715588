var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.$store.state.quickActions.loading,
      "spinner-variant": "primary",
      "rounded": "sm"
    }
  }, [_vm.$store.state.quickActions.loading ? _c('b-button', {
    staticClass: "p-5",
    attrs: {
      "variant": "outline-primary"
    }
  }) : _c(_vm.currentComponent, _vm._g(_vm._b({
    tag: "component"
  }, 'component', _vm.$store.state.quickActions.props, false, true), _vm.$store.state.quickActions.events))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-button v-show="show" :disabled="$store.state.quickActions.loading" variant="outline-primary" @click="handleClick">
    <i :class="$store.state.quickActions.loading ? icons.spinner : icons.save"></i>
    {{ $t("Save") }}
  </b-button>
</template>

<script>
import icons from "@/core/config/icons.js";

export default {
  props: {
    click: {
      type: [Function, Object],
      default: null,
    },
  },
  data() {
    return {
      icons,
    };
  },
  computed: {
    show() {
      return this.$route.name === "detail-secondment-travel-plans";
    },
  },
  methods: {
    handleClick() {
      if (this.click) this.click();
    },
  },
};
</script>

<style scoped>
.btn.btn-outline-primary {
  background: #fff;
}
</style>

<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--begin: Quick Actions -->
    <b-dropdown v-if="!isOnlyMaps" ref="dropdownAction" size="sm" variant="link"
      toggle-class="topbar-item text-decoration-none" no-caret right no-flip>
      <template v-slot:button-content>
        <div class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1">
          <span class="svg-icon svg-icon-xl">
            <inline-svg src="/media/svg/icons/Code/Warning-2.svg" />
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <KTDropdownQuickAction></KTDropdownQuickAction>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Quick Actions -->

    <!--begin: Notification Bar -->
    <template v-if="isAuthenticated && hasLoadedUserInfo">
      <KTQuickPanel></KTQuickPanel>
    </template>
    <!--end: Notification Bar -->

    <!--begin: My Cart -->
    <b-dropdown v-if="!isOnlyMaps" ref="dropdownShop" size="sm" variant="link"
      toggle-class="topbar-item text-decoration-none" no-caret right no-flip>
      <template v-slot:button-content>
        <div class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1 position-relative"
          :class="{ 'pulse pulse-danger': notCartEmpty }">
          <span v-if="notCartEmpty" aria-hidden="true"
            class="label label-danger label-md position-absolute label-notification">{{
              cartItems }}</span>
          <span class="svg-icon svg-icon-xl">
            <inline-svg src="/media/svg/icons/Shopping/Cart3.svg" />
          </span>
          <template v-if="notCartEmpty">
            <span class="pulse-ring"></span>
          </template>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <KTDropdownMyCart></KTDropdownMyCart>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: My Cart -->

    <!--begin: Language bar -->
    <b-dropdown ref="LanguageDropdown" size="sm" variant="link" toggle-class="topbar-item text-decoration-none" no-caret
      right no-flip>
      <template v-slot:button-content>
        <div class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1 position-relative">
          <img class="h-20px w-20px rounded-sm" :src="getLanguageFlag" alt="" />
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-175px">
        <KTDropdownLanguage @hide-dropdown="hideDropdown"></KTDropdownLanguage>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Language bar -->

    <!--begin: User Bar -->
    <KTQuickUser></KTQuickUser>
    <!--end: User Bar -->
    <!-- begin:: Header maintenance alert -->
    <div v-if="getCurrentMaintenanceWindows" class="maintenance-alert-container">
      <div class="alert alert-custom alert-notice fade show" role="alert" :class="getCountdown(getCurrentMaintenanceWindows.start) ? 'alert-light-danger' : 'alert-light-warning'">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text">
          <p>{{ getCurrentMaintenanceWindows.message }}</p>
          <p>{{ $t('The system will be temporarily unavailable for scheduled maintenance') }}</p>
          <p v-if="getCountdown(getCurrentMaintenanceWindows.start)" class="font-weight-bold">{{ getCurrentMaintenanceWindows.remaining }} {{ getCurrentMaintenanceWindows.remaining === 1 ? $t('minute remaining') : $t('minutes remaining') }}</p>
          <span v-else>
            <span class="mr-3">{{ $t('from_datetime') }}</span>
            <span class="mr-3">{{ formatDateTime(getCurrentMaintenanceWindows.start) }}</span>
            <span class="mr-3">{{ $t('to_datetime') }}</span>
            <span>{{ formatDateTime(getCurrentMaintenanceWindows.end) }}</span>
          </span>
        </div>
        <div class="alert-close">
          <button type="button" class="close" @click="closeAlert()" aria-label="Close">
            <span aria-hidden="true"><i class="ki ki-close"></i></span>
          </button>
        </div>
      </div>
    </div>
    <!-- end:: Header maintenance alert -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<script>
import KTDropdownLanguage from "@/view/layout/extras/dropdown/DropdownLanguage.vue";
import KTDropdownQuickAction from "@/view/layout/extras/dropdown/DropdownQuickAction.vue";
import KTDropdownMyCart from "@/view/layout/extras/dropdown/DropdownMyCart.vue";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";
import i18nService from "@/core/services/i18n.service.js";
import { mapState, mapGetters } from "vuex";
import eventBus from "@/core/services/event/eventBus";
import { differenceInMinutes } from 'date-fns'

export default {
  name: "KTTopbar",
  components: {
    KTDropdownLanguage,
    KTDropdownMyCart,
    KTDropdownQuickAction,
    KTQuickUser,
    KTQuickPanel
  },
  data() {
    return {
      languages: i18nService.languages,
    };
  },
  computed: {
    ...mapState("auth", ["status"]),
    ...mapState("cart", ["items"]),
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapGetters("user", ["hasLoadedUserInfo", "isOnlyMaps"]),
    ...mapGetters("constants", ["getMaintenanceWindows"]),

    getLanguageFlag() {
      return this.languages.find(val => {
        return val.lang === this.$i18n.locale;
      }).flag;
    },
    notCartEmpty() {
      return this.items.length > 0
    },
    cartItems() {
      return this.items.length
    },
    getCurrentMaintenanceWindows() {
      const now = new Date();
      const maintenanceWindowsCopy = [...this.getMaintenanceWindows];
      const nextWindow = maintenanceWindowsCopy
        .sort((a, b) => new Date(a.start) - new Date(b.start))
        .find(window => new Date(window.start) > now);
    
      if (nextWindow) {
        return {
          ...nextWindow,
          remaining: differenceInMinutes(new Date(nextWindow.start), now)
        };
      } else {
        return undefined;
      }
    },
  },
  mounted() {
    eventBus.$on('open-mini-cart', () => {
      this.$refs.dropdownShop.show(true);
    });
    eventBus.$on('close-mini-cart', () => {
      this.$refs.dropdownShop.hide();
    });
  },
  beforeDestroy() {
    eventBus.$off('close-mini-cart');
    eventBus.$off('open-mini-cart');
  },
  methods: {
    hideDropdown() {
      this.$refs.LanguageDropdown.hide();
    },
    closeAlert() {
      this.$store.commit("constants/SET_MAINTENANCE_WINDOWS", []);
    },
    formatDateTime(datetime) {
      const date = new Date(datetime);
      const options = {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'};
      return date.toLocaleDateString(this.$i18n.locale, options);
    },
    getCountdown(start) {
      const diff = differenceInMinutes(new Date(start), new Date());
      return diff < 60 ? diff : null;
    },
  }
};
</script>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
.maintenance-alert-container {
  position: absolute;
  width: 33vw;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}
</style>
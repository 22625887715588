<template>
  <router-link v-show="isWizardPending" :to="{
    name: 'edit-secondment',
    params: { secondmentId: secondmentId },
  }" class="btn btn-secondary font-weight-bold py-3 px-6 mr-2">
    <span class="navi-icon">
      <i class="la la-hand-point-left"></i>
    </span>
    <span>{{ $t("Back to wizard") }}</span>
  </router-link>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "BackToWizardBtn",
  data() {
    return {
      isCreating: true,
    };
  },
  computed: {
    ...mapState('wizard', ["secondmentId"]),
    isWizardPending() {
      return this.$route.query.pending;
    },
  }
};
</script>

<style scoped>
.btn.btn-outline-light a {
  color: #fff;
}

.btn.btn-outline-primary a {
  color: var(--primary);
}

.btn.btn-outline-light i::before {
  color: #fff;
}

.btn.btn-outline-light:hover span,
.btn.btn-outline-light:hover a,
.btn.btn-outline-light:hover i::before {
  color: var(--primary);
}

::v-deep .custom-buttom {
  color: white;
}

::v-deep .custom-buttom:hover {
  background: white;
  color: var(--primary);
}
</style>

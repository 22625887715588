const i18nService = {
  defaultLanguage: "en",

  languages: [{
      lang: "en",
      name: "English",
      flag: process.env.BASE_URL + "media/svg/flags/260-united-kingdom.svg",
    },
    {
      lang: "it",
      name: "Italiano",
      flag: process.env.BASE_URL + "media/svg/flags/013-italy.svg",
    },
    {
       lang: "fr",
       name: "Français",
       flag: process.env.BASE_URL + "media/svg/flags/195-france.svg",
     },
    // {
    //   lang: "ch",
    //   name: "简体中文",
    //   flag: process.env.BASE_URL + "media/svg/flags/034-china.svg",
    // },
    {
      lang: "es",
      name: "Español",
      flag: process.env.BASE_URL + "media/svg/flags/128-spain.svg",
    },
    // {
    //   lang: "ru",
    //   name: "Русский",
    //   flag: process.env.BASE_URL + "media/svg/flags/013-russia.svg",
    // },
    // {
    //   lang: "de",
    //   name: "Deutsch",
    //   flag: process.env.BASE_URL + "media/svg/flags/162-germany.svg",
    // },
  ],

  /**
   * Keep the active language in the localStorage
   * @param lang
   */
  setActiveLanguage(lang) {
    localStorage.setItem("language", lang);
  },

  /**
   * Get the current active language
   * @returns {string | string}
   */
  getActiveLanguage() {
    return localStorage.getItem("language") || this.defaultLanguage;
  },
};

export default i18nService;
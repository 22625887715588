<template>
  <div id="kt_footer" class="footer py-4 d-flex flex-lg-column bg-white">
    <!--begin::Container-->
    <div class="d-flex flex-column flex-md-row align-items-center justify-content-between"
      :class="{ 'container-fluid': widthFluid, container: !widthFluid }">
      <!--begin::Copyright-->
      <div class="order-2 order-md-1">
        <span class="font-weight-bold mr-2">2022-{{ currentYear }}©</span>
        Copyright Arletti Partners | VAT ID: IT02519610360
      </div>
      <!--end::Copyright-->
      <!--begin::Nav-->
      <div class="order-1 order-md-2 nav nav-dark">
        <div class="d-flex">
          <a href="https://arlettipartners.com/contattaci" target="_blank" class="nav-link pl-3 pr-0">{{ $t("Contact") }}</a>
          <router-link :to="{ name: 'terms-conditions' }" class="nav-link pl-3 pr-0">{{ $t("Terms & Conditions") }}</router-link>
          <router-link :to="{ name: 'general-conditions' }" class="nav-link pl-3 pr-0">{{ $t("General Conditions") }}</router-link>
          <router-link :to="{ name: 'cookies-policy' }" class="nav-link pl-3 pr-0">{{ $t("Cookie Policy") }}</router-link>
          <router-link :to="{ name: 'privacy-policy' }" class="nav-link pl-3 pr-0">{{ $t("Privacy Policy") }}</router-link>
        </div>
      </div>
      <!--end::Nav-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },
    currentYear() {
      return new Date().getFullYear();
    }
  },
};
</script>
